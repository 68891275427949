import React from "react";
import { Card } from "react-bootstrap";
import { HiOutlineMailOpen, HiStar, HiCursorClick } from "react-icons/hi";

function Cards() {
    return (
        <>
            <GetData />
        </>
    );
}

function GetData() {
    const data = [
        {
            id: 1,
            icon: <HiOutlineMailOpen />,
            name: "Step 1",
            desc: "Buka Inbox Email Kamu Pergi ke Inbox, cari email dari Jose Aditya. Jika tidak ada lihat di tab 'promotion', ataupun folder spam."
        },
        {
            id: 2,
            icon: <HiStar />,
            name: "Step 2",
            desc: "Tandai Bintang Lalu tandai bintang, agar email tersebut lebih mudah untuk ditemukan di inbox kamu"
        },
        {
            id: 3,
            icon: <HiCursorClick />,
            name: "Step 3",
            desc: "Pindahkan Ke tab primary Agar Kamu Selalu mendapat update terbaru dari saya"
        }
    ];
    const listData = data.map((itemData) =>
        <div key={itemData.id} className="col-lg-4 mb-5">
            <Card className="__card-height">
                <div className="__wrappe-icons-card text-center">
                    {itemData.icon}
                </div>
                <Card.Body>
                    <Card.Title className="text-center title-card">
                        {itemData.name}
                    </Card.Title>
                    <Card.Text className="text-center desc-card">
                        {itemData.desc}
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
    return (
        <>
            {listData}
        </>
    );
}

export default Cards;