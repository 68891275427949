import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Image } from 'react-bootstrap';
import Cardtq from '../components/elements/card';


class Thankyou extends React.Component {
    render() {
        return (
            <>
                <Layout pageInfo={{ pageName: "thank you" }}>
                    <SEO title="Thank You" keywords={[`Man Mastery`, `Man`, `Mastery`]} />

                    {/* All Section */}
                    <div className="container-fluid bg-full-tq">
                        <div className="container">

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="__wrappe-logo-primary">
                                        <Image className="img-fluid" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/man-mastery/logo/logo-no-headline.png" alt="logo man mastery" />
                                    </div>
                                    {/* end logo man mastery */}
                                </div>
                                <div className="col-lg-12">
                                    <div className="__headline-thankyou">
                                        <h2 className="text-white text-center">Selamat!</h2>
                                        <p className="text-white text-center">Kamu telah terdaftar dalam waiting list Man Mastery.<br />Silakan Cek Email Kamu (Inbox,Spam Atau Promotion) untuk dapatkan penawaran spesial</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <Cardtq />
                            </div>
                        </div>
                    </div>
                    {/* End All Section */}

                </Layout>
            </>
        )
    }
}
export default Thankyou;

